import BigNumber from "bignumber.js";

import { ONE, TEN, ZERO } from "@/constants/math";

export const enum PositionType {
  Uninitialized,
  Lend,
  Short,
  Long,
}

export function positionEnumToStr(posType?: PositionType): string {
  switch (posType) {
    case PositionType.Uninitialized:
      return "Uninitialized";
    case PositionType.Lend:
      return "Lend";
    case PositionType.Short:
      return "Short";
    case PositionType.Long:
      return "Long";
    default:
      return "error";
  }
}

export const convertPosition = (
  position?:
    | [number, number, bigint, bigint]
    | readonly [number, number, bigint, bigint],
  baseDecimals?: number,
  quoteDecimals?: number,
) => {
  if (
    !position ||
    !Number.isFinite(baseDecimals) ||
    !Number.isFinite(quoteDecimals)
  ) {
    return undefined;
  }

  const [_type, heapPosition, _discountedBaseAmount, _discountedQuoteAmount] =
    position;

  const type = _type as PositionType;

  const discountedBaseAmount = TEN.pow(-(baseDecimals ?? 0)).times(
    _discountedBaseAmount.toString(),
  );

  const discountedQuoteAmount = TEN.pow(-(quoteDecimals ?? 0)).times(
    _discountedQuoteAmount.toString(),
  );

  return {
    heapPosition,
    type,
    discountedBaseAmount,
    discountedQuoteAmount,
  };
};

type Position = ReturnType<typeof convertPosition>;

export const calcPositionParams = ({
  position,
  basePrice,
  baseCollateralCoeff = ZERO,
  quoteCollateralCoeff = ZERO,
  baseDebtCoeff = ZERO,
  quoteDebtCoeff = ZERO,
  baseDelevCoeff = ZERO,
  quoteDelevCoeff = ZERO,
}: {
  position?: Position;
  basePrice?: BigNumber;
  baseCollateralCoeff?: BigNumber;
  quoteCollateralCoeff?: BigNumber;
  baseDebtCoeff?: BigNumber;
  quoteDebtCoeff?: BigNumber;
  baseDelevCoeff?: BigNumber;
  quoteDelevCoeff?: BigNumber;
}) => {
  if (!position || !basePrice) {
    return undefined;
  }

  let collateral = ZERO;
  let debt = ZERO;
  let leverage = ONE;
  let baseCoeff = ZERO;
  let quoteCoeff = ZERO;

  switch (position.type) {
    case PositionType.Lend: {
      // TODO check baseCollateralCoeff, quoteCollateralCoeff
      [baseCoeff, quoteCoeff] = [baseCollateralCoeff, quoteCollateralCoeff];

      collateral = position.discountedQuoteAmount
        .times(quoteCoeff)
        .minus(quoteDelevCoeff.times(position.discountedBaseAmount))
        .plus(
          position.discountedBaseAmount
            .times(baseCoeff)
            .minus(baseDelevCoeff.times(position.discountedQuoteAmount))
            .times(basePrice),
        );

      debt = ZERO;
      break;
    }

    case PositionType.Short: {
      // TODO check baseDebtCoeff, quoteCollateralCoeff
      [baseCoeff, quoteCoeff] = [baseDebtCoeff, quoteCollateralCoeff];

      collateral = position.discountedQuoteAmount
        .times(quoteCoeff)
        .minus(quoteDelevCoeff.times(position.discountedBaseAmount));

      debt = position.discountedBaseAmount.times(baseCoeff).times(basePrice);

      leverage = collateral.div(collateral.minus(debt));
      break;
    }

    case PositionType.Long: {
      // TODO check baseCollateralCoeff, quoteDebtCoeff
      [baseCoeff, quoteCoeff] = [baseCollateralCoeff, quoteDebtCoeff];

      collateral = position.discountedBaseAmount
        .times(baseCoeff)
        .minus(baseDelevCoeff.times(position.discountedQuoteAmount))
        .times(basePrice);

      debt = position.discountedQuoteAmount.times(quoteCoeff);
      leverage = collateral.div(collateral.minus(debt));
      break;
    }

    default:
      //throw new Error("wrong position type");
      break;
  }

  const realBaseAmount = position.discountedBaseAmount
    .times(baseCoeff)
    .minus(baseDelevCoeff.times(position.discountedQuoteAmount))
    .abs();

  const realQuoteAmount = position.discountedQuoteAmount
    .times(quoteCoeff)
    .minus(quoteDelevCoeff.times(position.discountedBaseAmount))
    .abs();

  const net = collateral.minus(debt);
  const margin = net.div(collateral);

  return {
    baseCoeff,
    basePrice,
    quoteCoeff,
    collateral,
    debt,
    margin,
    net,
    leverage,
    realBaseAmount,
    realQuoteAmount,
  };
};

export type PositionParams = ReturnType<typeof calcPositionParams>;
