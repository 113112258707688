import { TEN, ZERO } from "@/constants/math";

import { convertFP96 } from "./common";

export const convertBasePrice = (
  basePrice?: { inner: bigint },
  baseDecimals?: number,
  quoteDecimals?: number,
) => {
  if (!Number.isFinite(baseDecimals) || !Number.isFinite(quoteDecimals)) {
    return ZERO;
  }

  const decimals = TEN.pow(baseDecimals! - quoteDecimals!);
  return convertFP96(basePrice?.inner).times(decimals);
};
