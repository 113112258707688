import type { Flatten } from "@/types/util";

import type { GetAbiType } from "../types";

type GetRawData<T> = T extends { type: infer U } ? GetAbiType<U> : never;

type GetResult<T> = T extends { name: infer U1; type: infer U2 }
  ? U1 extends string
    ? { [P in U1]: GetAbiType<U2> }
    : never
  : never;

type IterateResult<T> = T extends readonly [infer F, ...infer R]
  ? GetResult<F> & IterateResult<R>
  : {};

type IterateRawData<T> = T extends readonly [infer F, ...infer R]
  ? [GetRawData<F>, ...IterateRawData<R>]
  : [];

export const createTransform =
  <T>(abi: { readonly outputs: T }) =>
  (values?: IterateRawData<T>): Flatten<IterateResult<T>> | undefined => {
    const outputs = abi.outputs as { name: string; type: string }[];

    return values?.reduce((acc, val, i) => {
      const { name, type } = outputs[i] as {
        name: string;
        type: string;
      };
      const value: GetAbiType<typeof type> = val;
      return { ...acc, [name]: value };
    }, {} as Flatten<IterateResult<T>>);
  };
