import { useCallback, useMemo } from "react";
import { useAccount, useReadContract, useReadContracts } from "wagmi";

import { W3_CACHE } from "@/constants/common";
import { useRx } from "@/contexts/rx";
import { useWeb3Context } from "@/contexts/web3";
import {
  baseCollateralCoeffSelector,
  baseDebtCoeffSelector,
  baseDelevCoeffSelector,
  getBasePriceSelector,
  positionsSelector,
  quoteCollateralCoeffSelector,
  quoteDebtCoeffSelector,
  quoteDelevCoeffSelector,
} from "@/contracts/selectors/marginly-pool";
import paramsAdapter from "@/contracts/selectors/marginly-pool/params";
import { convertFP96 } from "@/contracts/transform/common";
import {
  calcPositionParams,
  convertPosition,
} from "@/contracts/transform/position";
import { convertBasePrice } from "@/contracts/transform/price";
import { usePushSubject } from "@/hooks/rx";
import { markWritable } from "@/util/core";
import { getPoolData } from "@/util/position";

import { useMarginlyApi } from "./api";
import { useFeatureFlags } from "./feature";
import { useApply } from "./util";

export const usePoolCoeffs = (pool?: `0x${string}`) => {
  const { error$ } = useRx();

  const commonQueries = useMemo(
    () => [
      baseCollateralCoeffSelector(pool),
      baseDebtCoeffSelector(pool),
      quoteCollateralCoeffSelector(pool),
      quoteDebtCoeffSelector(pool),
    ],
    [pool],
  );

  // todo combine in one when ready
  const deleverageQueries = useMemo(
    () => [baseDelevCoeffSelector(pool), quoteDelevCoeffSelector(pool)],
    [pool],
  );

  const common = useReadContracts({
    contracts: commonQueries,
    ...W3_CACHE.NORMAL,
  });

  usePushSubject(error$, common.error);

  const deleverage = useReadContracts({
    contracts: deleverageQueries,
    query: {
      enabled: true,
      ...W3_CACHE.NORMAL,
    },
  });

  usePushSubject(error$, deleverage.error);

  const refetch = useCallback(async () => {
    await Promise.all(
      [
        pool && common.data ? common.refetch() : undefined,
        pool && deleverage.data ? deleverage.refetch() : undefined,
      ].filter(Boolean),
    );
  }, [pool, common, deleverage]);

  const coeffs = useMemo(() => {
    const [bcCoeff96, bdCoeff96, qcCoeff96, qdCoeff96] = common.data ?? [];
    const [bDelevCoeff96, qDelevCoeff96] = deleverage.data ?? [];

    const [
      baseCollateralCoeff,
      baseDebtCoeff,
      quoteCollateralCoeff,
      quoteDebtCoeff,
      baseDelevCoeff,
      quoteDelevCoeff,
    ] = [
      bcCoeff96?.result,
      bdCoeff96?.result,
      qcCoeff96?.result,
      qdCoeff96?.result,
      bDelevCoeff96?.result,
      qDelevCoeff96?.result,
      // @ts-expect-error fixme typings
    ].map(convertFP96);

    return {
      baseCollateralCoeff,
      baseDebtCoeff,
      quoteCollateralCoeff,
      quoteDebtCoeff,
      baseDelevCoeff,
      quoteDelevCoeff,
    };
  }, [common.data, deleverage.data]);

  return {
    ...coeffs,
    refetch,
    isLoading: common.isLoading || deleverage.isLoading,
  };
};

type UsePoolCoeffs = ReturnType<typeof usePoolCoeffs>;
type PoolCoeffs = Omit<UsePoolCoeffs, "refetch" | "isLoading">;

export const usePoolParams = (pool?: `0x${string}`) => {
  const { error$ } = useRx();
  const flip = useFeatureFlags(pool)?.flip;
  const { selector, transform } = paramsAdapter({ flip });

  const params = useReadContract({
    ...selector(pool),
    query: {
      enabled: true,
      staleTime: W3_CACHE.ETERNAL.staleTime,
    },
  });

  usePushSubject(error$, params.error);

  const value = useMemo(
    () => transform(markWritable(params.data)),
    [params.data, transform],
  );

  return { isLoading: params.isLoading, ...value };
};

export const usePoolData = (pool?: `0x${string}`) => {
  const account = useAccount();
  const { error$ } = useRx();
  const { getPoolByAddress } = useWeb3Context();

  const {
    baseToken: base,
    quoteToken: quote,
    pool: _pool,
  } = useApply(getPoolByAddress, pool) ?? {};

  const { uniswapAddress, unwrap, awaiting } = _pool ?? {};

  const pos = useReadContract({
    ...positionsSelector(pool, account.address),
    query: {
      staleTime: W3_CACHE.SHORT.staleTime,
    },
    scopeKey: "position",
  });

  const basePrice96 = useReadContract({
    ...getBasePriceSelector(pool),
    query: {
      staleTime: W3_CACHE.SHORT.staleTime,
    },
  });

  usePushSubject(error$, pos.error);
  usePushSubject(error$, basePrice96.error);

  const { position, basePrice } = useMemo(() => {
    const position = convertPosition(
      pos?.data,
      base?.decimals,
      quote?.decimals,
    );

    const basePrice = convertBasePrice(
      basePrice96?.data,
      base?.decimals,
      quote?.decimals,
    );

    return {
      position,
      basePrice,
    };
  }, [base?.decimals, basePrice96.data, pos.data, quote?.decimals]);

  const refetch = useCallback(async () => {
    try {
      await Promise.all(
        [
          account.address && pool && pos.data ? pos.refetch() : undefined,
          pool && basePrice96.data ? basePrice96.refetch() : undefined,
        ].filter(Boolean),
      );
    } catch (e) {
      console.error(e);
    }
  }, [account.address, pool, pos, basePrice96]);

  const getPositionParams = useCallback(
    (coeffs: PoolCoeffs) =>
      calcPositionParams({ position, basePrice, ...coeffs }),
    [position, basePrice],
  );

  return {
    position,
    basePrice,
    base,
    quote,
    uniswapAddress,
    awaiting,
    unwrap,
    isLoading: pos.isLoading || basePrice96.isLoading,
    getPositionParams,
    refetch,
    baseAddress: _pool?.baseToken,
    quoteAddress: _pool?.quoteToken,
    isTokenInfoLoaded: base && quote,
  };
};

export type UsePoolData = ReturnType<typeof usePoolData>;
export type PoolPosition = NonNullable<UsePoolData["position"]>;

export const usePositionsInfo = (
  address?: `0x${string}`,
  {
    disableLiqPrice = false,
    leverage,
  }: { disableLiqPrice?: boolean; leverage?: number } = {},
) => {
  const { internalChainId } = useWeb3Context();
  const account = useAccount();
  const { pools } = useWeb3Context();

  const pool = useMemo(
    () => pools?.find((p) => p.address === address),
    [pools, address],
  );

  const { data: liq } = useMarginlyApi({
    enabled: !disableLiqPrice && !!account?.address,
    endpoint: "positionDetails",
    args: [internalChainId, address, account.address],
  });

  const { data: pnl } = useMarginlyApi({
    endpoint: "poolPnl",
    enabled: !!account?.address,
    args: [internalChainId, address, account.address],
  });

  const { data: apr } = useMarginlyApi({
    endpoint: "poolApr",
    args: [internalChainId, address, leverage],
  });

  return useMemo(
    () => getPoolData({ apr, liq, pool, pnl }),
    [pool, liq, pnl, apr],
  );
};
