import type {
  ApiPoolAprResponse,
  ApiPoolPnlResponse,
  ApiPositionDetailsResponse,
} from "@/types/api";
import { PoolConfig } from "@/types/core";

type PositionItem = {
  chainId: number;
  /** @deprecated */
  uniswapPoolAddress: `0x${string}`;
  pnl: ApiPoolPnlResponse["pnl"] | undefined;
  pnlPercentage: number;
  openPrice: number | undefined;
  openTimestamp: number | undefined;
  positionType: ApiPoolPnlResponse["positionType"] | undefined;
  currentBasePrice: ApiPoolPnlResponse["currentBasePrice"] | undefined;
  pnlPoints: ApiPoolPnlResponse["pnlPoints"] | undefined;
  userCollateralDeposit:
    | ApiPoolPnlResponse["userCollateralDeposit"]
    | undefined;
  collateralBalanceDelta:
    | ApiPoolPnlResponse["collateralBalanceDelta"]
    | undefined;
  liquidationPrice: ApiPositionDetailsResponse["liquidationPrice"] | undefined;
  lendCurrentBaseApr: ApiPoolAprResponse["lendCurrentBaseApr"];
  lendCurrentQuoteApr: ApiPoolAprResponse["lendCurrentQuoteApr"];
  borrowCurrentBaseApr: ApiPoolAprResponse["borrowCurrentBaseApr"];
  borrowCurrentQuoteApr: ApiPoolAprResponse["borrowCurrentQuoteApr"];
  apr: { earn: number; pay: number } | undefined;
};

const calcApr = (
  aprs: ApiPoolAprResponse | undefined,
  positionType: string | undefined,
): PositionItem["apr"] => {
  if (!aprs || !positionType) return undefined;

  const {
    lendCurrentBaseApr,
    lendCurrentQuoteApr,
    borrowCurrentBaseApr,
    borrowCurrentQuoteApr,
  } = aprs;

  if (positionType === "Long") {
    return {
      earn: lendCurrentBaseApr,
      pay: borrowCurrentQuoteApr,
    };
  }

  if (positionType === "Short") {
    return {
      earn: lendCurrentQuoteApr,
      pay: borrowCurrentBaseApr,
    };
  }

  if (positionType === "Lend") {
    return {
      earn: Math.max(lendCurrentQuoteApr, lendCurrentBaseApr),
      pay: 0,
    };
  }

  return undefined;
};

export const getPoolData = ({
  apr,
  liq,
  pool,
  pnl: _pnl,
}: {
  apr?: ApiPoolAprResponse;
  liq?: ApiPositionDetailsResponse;
  pool?: PoolConfig;
  pnl?: ApiPoolPnlResponse;
}) => {
  const pnl = _pnl?.pnl;
  const pnlPoints = _pnl?.pnlPoints.sort((a, b) => b.timestamp - a.timestamp);
  const latestPnlPoint = pnlPoints
    ? pnlPoints[pnlPoints.length - 1]
    : undefined;

  return {
    pnl,
    pnlToken: _pnl?.pnlToken,
    userCollateralDeposit: _pnl?.userCollateralDeposit,
    pnlPoints,
    pnlPercentage: _pnl?.pnlInPercents,
    openPrice: _pnl?.avgOpenPrice,
    openTimestamp: latestPnlPoint?.timestamp,
    chainId: pool?.chainId,
    /** @deprecated */
    uniswapPoolAddress: pool?.uniswapAddress,
    positionType: _pnl?.positionType,
    currentBasePrice: _pnl?.currentBasePrice,
    collateralBalanceDelta: _pnl?.collateralBalanceDelta,
    liquidationPrice: liq?.liquidationPrice,
    lendCurrentBaseApr: apr?.lendCurrentBaseApr,
    lendCurrentQuoteApr: apr?.lendCurrentQuoteApr,
    borrowCurrentBaseApr: apr?.borrowCurrentBaseApr,
    borrowCurrentQuoteApr: apr?.borrowCurrentQuoteApr,
    apr: calcApr(apr, _pnl?.positionType),
    farmingBaseApy: apr?.farmingBaseApy,
    details: liq,
  };
};
