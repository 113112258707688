import { createTransform } from "@/contracts/util/result";
import type { AbiNameFilter } from "@/types/abi";
import type { FeatureFlags } from "@/types/core";
import type { FilterEntry, UnwrapReadonlyArray } from "@/types/util";

import marginlyPoolAbi from "../../abi/marginly-pool";
import marginlyPoolV15Abi from "../../abi/marginly-pool-v15";

type AbiParamsEntry = AbiNameFilter<"params">;

type ParamsAbi = FilterEntry<
  UnwrapReadonlyArray<typeof marginlyPoolAbi>,
  AbiParamsEntry
>;

type ParamsAbiV15 = FilterEntry<
  UnwrapReadonlyArray<typeof marginlyPoolV15Abi>,
  AbiParamsEntry
>;

const PARAMS_ABI = marginlyPoolAbi.find(
  (entry) => "name" in entry && entry.name === "params",
)! as ParamsAbi;

const PARAMS_V15_ABI = marginlyPoolV15Abi.find(
  (entry) => "name" in entry && entry.name === "params",
)! as ParamsAbiV15;


const transformMarginlyParams = createTransform(PARAMS_ABI);
const transformMarginlyParamsV15 = createTransform(PARAMS_V15_ABI);

const marginlyParamsSelector = (address?: `0x${string}`) =>
  ({
    abi: [PARAMS_ABI],
    address,
    functionName: "params",
  } as const);

const marginlyParamsSelectorV15 = (address?: `0x${string}`) =>
  ({
    abi: [PARAMS_V15_ABI],
    address,
    functionName: "params",
  } as const);

interface AdapterV15 {
  selector: typeof marginlyParamsSelectorV15;
  transform: typeof transformMarginlyParamsV15;
}

interface Adapter {
  selector: typeof marginlyParamsSelector;
  transform: typeof transformMarginlyParams;
}

const createMarginlyPoolAdapter = <T extends FeatureFlags>(pool: T) =>
  (pool.flip
    ? {
        selector: marginlyParamsSelectorV15,
        transform: transformMarginlyParamsV15,
      }
    : {
        selector: marginlyParamsSelector,
        transform: transformMarginlyParams,
      }) as T extends { flip: true } ? AdapterV15 : Adapter;

export default createMarginlyPoolAdapter;
