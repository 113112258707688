import { useWeb3Context } from "@/contexts/web3";
import type { FeatureFlags, PoolConfig } from "@/types/core";
import { getEntries } from "@/util/core";

import { useApply } from "./util";

const extractFeatures = (
  _conf: PoolConfig[],
  selector: Partial<PoolConfig> | undefined,
  ...features: (keyof FeatureFlags)[]
): FeatureFlags | undefined => {
  const conf = _conf.find((x) =>
    getEntries(selector).every(([k, v]) => x[k] === v),
  );

  if (!conf) {
    return undefined;
  }

  return features.reduce<FeatureFlags>(
    (prev, feature) => ({ ...prev, [feature]: conf[feature] }),
    {},
  );
};

const createAddressSelector = (address?: `0x${string}`) => ({ address });

export const useFeatureFlags = (pool?: `0x${string}`) => {
  const { pools } = useWeb3Context();
  const selector = useApply(createAddressSelector, pool);
  return useApply(extractFeatures, pools, selector, "flip");
};
