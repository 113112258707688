import type { BigNumber } from "bignumber.js";
import * as chains from "viem/chains";

import { CallType } from "@/constants/enum";
import { TWO_POW96, ZERO } from "@/constants/math";
import { markWritable } from "@/util/core";

import MarginlyPool from "../abi/marginly-pool";
import MarginlyPoolV15 from "../abi/marginly-pool-v15";

type LegacyArgs = [
  number,
  bigint,
  bigint,
  // limitPriceX96
  boolean,
  "0x0000000000000000000000000000000000000000",
  // bigint - 0
];

type ExecuteArgs = [
  number,
  bigint,
  bigint,
  bigint,
  boolean,
  `0x${string}`,
  bigint,
];

interface ExecuteParams {
  abi: typeof MarginlyPool; // | typeof MarginlyPoolV15;
  args: ExecuteArgs;
  chainId?: number;
  functionName: "execute";
}

const B0 = BigInt(0);
const bigAbs = (a: bigint) => (a > B0 ? a : -a);
const SLIPPAGE = 0.05;

const FLIP_MAP = {
  [CallType.Short]: CallType.Long,
  [CallType.Long]: CallType.Short,
} as const;

const flipCallType = (callType: CallType) => {
  if (callType in FLIP_MAP) {
    return FLIP_MAP[callType as keyof typeof FLIP_MAP];
  }

  throw new Error(`Unsupported callType: ${callType}`);
};

/** @deprecated fixme need refactor  */
export const getExecuteParams = (
  _args?: LegacyArgs,
  /** @deprecated refactor */
  _stuff?: {
    basePrice?: BigNumber;
    baseDecimals?: number;
    quoteDecimals?: number;
    positionType?: "long" | "short";
    swapCallData?: string;
    version?: "v1" | "v15";
  },
): ExecuteParams | undefined => {
  if (!_args) {
    return undefined;
  }

  const version = _stuff?.version ?? "v1";
  let flip = false;

  // fixme dirty hack
  let chainId: undefined | number;
  if (typeof window !== "undefined") {
    const [, key] = window.location.pathname.split("/");
    if (key === "blast") {
      chainId = 81457;
    } else if (typeof key === "string") {
      chainId = chains[key as keyof typeof chains]?.id;
    }
  }

  let limitPriceX96 = BigInt(0);
  const swapCallData = BigInt(_stuff?.swapCallData ?? 0);
  const [callType, amount0, amount1, flag, receivePositionAddress] = _args;
  const basePrice = _stuff?.basePrice ?? ZERO;
  const baseDecimals = _stuff?.baseDecimals ?? 0;
  const quoteDecimals = _stuff?.quoteDecimals ?? 0;

  if (callType === CallType.ClosePosition) {
    // assert(basePrice, "basePrice is required");
    const positionType = _stuff?.positionType;
    limitPriceX96 = BigInt(
      basePrice
        .div(10 ** (baseDecimals - quoteDecimals))
        .times(positionType === "short" ? 1 + SLIPPAGE : 1 - SLIPPAGE)
        .times(TWO_POW96)
        .dp(0)
        .toString(10),
    );
  } else if (
    callType === CallType.Long ||
    (callType === CallType.DepositBase && bigAbs(amount1) > 0)
  ) {
    const isDeposit = callType === CallType.DepositBase;
    const amount = !isDeposit ? amount0 : amount1;
    flip = !isDeposit && amount < 0;
    // assert(basePrice, "basePrice is required");

    limitPriceX96 = BigInt(
      basePrice
        .div(10 ** (baseDecimals - quoteDecimals))
        .times(amount > 0 ? 1 + SLIPPAGE : 1 - SLIPPAGE)
        .times(TWO_POW96)
        .dp(0)
        .toString(10),
    );
  } else if (
    callType === CallType.Short ||
    (callType === CallType.DepositQuote && bigAbs(amount1) > 0)
  ) {
    const isDeposit = callType === CallType.DepositQuote;
    const amount = !isDeposit ? amount0 : amount1;
    flip = !isDeposit && amount < 0;
    // assert(basePrice, "basePrice is required");
    limitPriceX96 = BigInt(
      basePrice
        .div(10 ** (baseDecimals - quoteDecimals))
        .times(amount > 0 ? 1 - SLIPPAGE : 1 + SLIPPAGE)
        .times(TWO_POW96)
        .dp(0)
        .toString(10),
    );
  }

  const args = markWritable([
    flip ? flipCallType(callType) : callType,
    flip ? -amount0 : amount0,
    amount1,
    limitPriceX96,
    flag,
    receivePositionAddress,
    swapCallData,
  ] as const);

  return {
    // fixme
    // @ts-expect-error
    abi: version === "v1" ? MarginlyPool : MarginlyPoolV15,
    chainId,
    functionName: "execute",
    args,
  };
};
